import React from 'react';
import { motion } from 'framer-motion';
import fog from '../../assets/images/land/Midground_fog.png'

const fogProps = (start, end) => {
    const { x: startX, y: startY } = start;
    const { x: endX, y: endY } = end;
    return (
        {
            hidden: {
                x: startX,
            },
            visible: {
                x: endX,
                transition: {
                    repeat: Infinity,
                    duration: 3,
                    ease: "linear"
                }
            },
        }
    )

}

const Fog = (props) => {
    return (
        <motion.div
            {...props}
            variants={fogProps(props.start, props.end)}
            initial="hidden"
            animate="visible"
        >
            <img src={fog} style={{ width: '100%', height: 'auto' }} />
        </motion.div>
    )
}

export default Fog;
