import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const LandArea = (props) => {

  const coinflip = () => {
    let random = Math.floor(Math.random() * 100) + 1;
    if (random > 60) {
      return 'orange'
    } else if (random > 20 && random <= 90) {
      return '#3c3944'
    } else {
      return '#355544'
    }
  }

  const landArea = (land) => {
    const yCoords = land.data && land.data.map((y, currY) => {
      const xCoords = y.map((x, i) => {
        if (x === null) {
          return (
            <>
              <div style={{ border: '1px solid #9e9e9e', width: `calc(100%/${y.length})`, height: `calc(1000px/${land.data.length})`, display: 'inline-block', backgroundColor: `#d3d3d3d3` }}>
                &nbsp;
              </div>
            </>
          )
        } else if (x.owner !== null) {
          return <>
            <div
              onClick={() => { props.toggleMemorial && props.toggleMemorial((currY * 15) + i + 1, x.owner); }} // TODO this is not 15 for levels 2+, fix!!!
              onMouseOver={() => { props.setSelected(true); props.setOwner(x.owner); }}
              onMouseOut={() => { props.setSelected(false) }}
              onFocus={() => { props.setSelected(true); props.setOwner(x.owner); }}
              onBlur={() => { props.setSelected(false) }}
              className="zoom"
              style={{
                border: '1px solid black',
                width: `calc(100%/${y.length})`,
                height: `calc(1000px/${land.data.length})`,
                display: 'inline-block',
                backgroundColor: `#ea7639`,
                cursor: 'pointer',
              }}>
              &nbsp;
            </div>
          </>
        } else {
          return <>
            <div
              onMouseOver={() => { props.setSelected(true); props.setOwner(x.owner); }}
              onMouseOut={() => { props.setSelected(false) }}
              onFocus={() => { props.setSelected(true); props.setOwner(x.owner); }}
              onBlur={() => { props.setSelected(false) }}
              className="zoom"
              style={{
                border: '1px solid black',
                width: `calc(100%/${y.length})`,
                height: `calc(1000px/${land.data.length})`,
                display: 'inline-block',
                backgroundColor: `#eea654`,
                cursor: 'crosshair',
              }}>
              &nbsp;
            </div>
          </>
        }
      })
      return <div>{xCoords}</div>
    })

    return yCoords;
  }
  return (
    <>
      {props.level && landArea(props.level)}
    </>
  )
}

export default LandArea;
