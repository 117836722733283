import React from 'react';
import { motion } from 'framer-motion';
import birds from '../../assets/images/land/Birds.png'

const birdProps = (start, end, delay, duration) => {
    const { x: startX, y: startY } = start;
    const { x: endX, y: endY } = end;
    return (
        {
            hidden: {
                x: startX,
                y: startY,
            },
            visible: {
                x: endX,
                y: endY,
                transition: {
                    repeat: Infinity,
                    repeatDelay: 5,
                    delay,
                    duration,
                    ease: "linear"
                }
            },
        }
    )

}

const Birds = (props) => {
    return (
        <motion.div
            {...props}
            variants={birdProps(props.start, props.end, props.delay, props.duration)}
            initial="hidden"
            animate="visible"
        >
            <img src={birds} style={{ height: 'auto' }} />
        </motion.div>
    )
}

export default Birds;
