import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby'
import ReactWOW from 'react-wow';
import LandModal from '../Land/LandModal';
import MemorialModal from '../Land/MemorialModal';
import DisabledModel from '../Land/DisabledModel'
import { Row, Col, Container } from 'react-bootstrap'
import Pyramid from '../Pyramid/Pyramid'

import midground from '../../assets/images/land/Midground_Mountain.png'
import foreground from '../../assets/images/land/Foreground.png'
import birds from '../../assets/images/land/Birds.png'

import Fog from '../Decos/Fog'
import Birds from '../Decos/Brids'
import axios from 'axios'
import {BASE_URL} from '../../utils/utils'



const MainBanner = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [memorialIsOpen, setMemorialIsOpen] = useState(false);
    const [isDisabledOpen, setIsDisabledOpen] = useState(false);
    const [memorialId, setMemorialId] = useState(0);
    const [memorialOwner, setMemorialOwner] = useState(0);
    const [section, setSection] = useState(1);
    const [level, setLevel] = useState(null)

    useEffect(() => {
        let ignore = false;
        async function fetchData() {
            const { data: levels } = await axios(`${BASE_URL}/v0/levels/${section}`);
            if (!ignore) setLevel(levels);
        }
        fetchData();
        return () => { ignore = true; }
    }, [section])

    const toggleMemorial = (id, owner) => {
        setMemorialId(id);
        setMemorialOwner(owner);
        setMemorialIsOpen(true);
    }

    const toggleLand = (section) => {
        setIsOpen(!isOpen);
        setSection(section)
    }

    const toggleDisabled = (section) => {
        setIsDisabledOpen(!isDisabledOpen);
        setSection(section)
    }

    return (
        <div className="main-banner-area" style={{ position: 'relative', overflow: 'hidden', height: '100vh' }}>
            <Birds
                style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    zIndex: -1
                }}
                className='background-birds'
                start={{ x: "0px", y: "0px" }}
                end={{ x: "-1000px", y: "-800px" }}
                delay={3}
                duration={1.5}
            />
            <div style={{
                position: 'absolute',
                bottom: '0px',
                width: '100%',
                pointerEvents: 'none',
                zIndex: 5
            }}>
                <img src={foreground} style={{ width: '150%', height: 'auto' }} />
            </div>
            <Fog
                style={{
                    position: 'absolute',
                    bottom: '-150px',
                    width: '100%',
                    pointerEvents: 'none',
                    zIndex: 4,
                }}
                start={{ x: "300px", y: "0px" }}
                end={{ x: "0px", y: "0px" }} />
            <div style={{
                position: 'absolute',
                bottom: '0px',
                width: '100%',
                margin: 'auto',
                pointerEvents: 'none',
                zIndex: 3
            }}>
                <img src={midground} style={{ width: '100%', height: '50%' }} />
            </div>
            <Fog
                style={{
                    position: 'absolute',
                    bottom: '20px',
                    width: '100%',
                    pointerEvents: 'none',
                    zIndex: 2,
                }}
                start={{ x: "0px", y: "0px" }}
                end={{ x: "300px", y: "0px" }} />
            <Birds
                style={{
                    position: 'absolute',
                    pointerEvents: 'none',
                    zIndex: 1
                }}
                className='midground-birds'
                start={{ x: "0px", y: "0px" }}
                end={{ x: "800px", y: "-1500px" }}
                delay={2}
                duration={1}
            />

            <div className="container">
                {/* <LandModal toggleMemorial={toggleMemorial.bind(this)} level={level} section={section} show={isOpen} onHide={() => setIsOpen(!isOpen)} onEscapeKeyDown={() => setIsOpen(!isOpen)} /> */}
                <MemorialModal id={memorialId} owner={memorialOwner} show={memorialIsOpen} onHide={() => setMemorialIsOpen(!memorialIsOpen)} onEscapeKeyDown={() => setMemorialIsOpen(!isOpen)} />
                <DisabledModel section={section} show={isDisabledOpen} onHide={() => setIsDisabledOpen(!isDisabledOpen)} onEscapeKeyDown={() => setIsDisabledOpen(!isOpen)} />
                <div className="banner-area-content">
                    <ReactWOW delay='.38s' animation='fadeInUpBig'>
                        <h1>Preserve Your Legacy</h1>
                    </ReactWOW>

                    <ReactWOW delay='.38s' animation='fadeInUpBig'>
                        <h5>Memorial.network is a community-driven metaverse of permanent digital memorials (NFTs) dedicated to honoring loved ones and preserving family history.</h5>
                    </ReactWOW>
                </div>

                <ReactWOW delay='.1s' animation='fadeInLeft'>
                    <Pyramid toggleMemorial={toggleMemorial.bind(this)} toggleLand={toggleLand.bind(this)} toggleDisabled={toggleDisabled.bind(this)} level={level} section={section} />
                </ReactWOW>

            </div>
        </div>
    )
}

export default MainBanner;
