import React, { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap'
import axios from 'axios'
import {BASE_URL} from '../../utils/utils'

const MemorialModal = (props) => {
  const [imagesUrl, setImagesUrl] = useState([]);
  const [displayImageId, setDisplayImageId] = useState(0)

  const Arrow = (props) => {
    function checkNext(forward) {
      if (forward) {
        if (displayImageId === imagesUrl.length - 1) {
          setDisplayImageId(0);
        } else {
          setDisplayImageId(displayImageId + 1);
        }
      } else {
        if (displayImageId === 0) {
          setDisplayImageId(imagesUrl.length - 1);
        } else {
          setDisplayImageId(displayImageId - 1);
        }
      }
    }
    return (
      <img src='/museumarrow.png' style={props.style} onClick={() => { checkNext(props.forward) }} class={props.forward ? '' : 'previous'} />
    )
  }

  useEffect(() => {
    let ignore = false;
    const getPictures = async () => {
      if (!ignore) {
        const data = (await axios(`${BASE_URL}/v0/memorialpics/${props.id}`)).data;
        setImagesUrl(data);
      }
      return () => { ignore = true; }
    }
    getPictures();
  }, [props.id])

  return (
    <>
      <Modal
        {...props}
        size="xl"
        centered
      >
        <div style={{ height: '50wh', perspective: '309px', position: 'relative' }}>
          <Arrow forward={true} style={{
            position: 'absolute',
            top: 299,
            right: 658,
            height: 40,
            transform: 'rotateY(4deg)',
            cursor: 'pointer',
          }} />
          <Arrow forward={false} style={{
            position: 'absolute',
            top: 299,
            right: 1058,
            height: 40,
            transform: 'rotateY(210deg)',
            cursor: 'pointer',
          }} />
          <img src="memmuseum.png" />
          <img style={{
            height: 343,
            width: 450,
            objectFit: 'cover',
            transform: 'rotateY(4deg)',
            transformOrigin: '-520% 0% 0px',
            filter: 'brightness(1.26) saturate(1.4) sepia(0.6)',
            top: 140,
            right: 808,
            position: 'absolute',
            pointerEvents: 'none',
          }}
            src={imagesUrl[displayImageId]}></img>
        </div>
        <Modal.Footer>
          <span className="mr-auto">
            <h4>Memorial {props.id || ''}</h4>
            <div>Owned by {props.owner}</div>
          </span>
          <Button style={{ backgroundColor: '#f8a035', borderColor: '#f8a035' }} onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MemorialModal;
