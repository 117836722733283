import React from 'react';
import { useRecoilState } from 'recoil'
import { collapsedState } from '../../utils/recoil-atoms'
import { Link } from 'gatsby'
import logo from "../../assets/images/logo.png"

const NavbarThree = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
  const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

  return (
      <React.Fragment>
        <div id="navbar" className="navbar-area">
          <div className="tarn-nav">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link
                    to="/"
                    onClick={() => setCollapsed(true)}
                    className="navbar-brand"
                >
                  <img src={logo} alt="logo" className="sm-logo mt-3"/>
                </Link>

                <button
                    onClick={toggleNavbar}
                    className={classTwo}
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                  <span className="icon-bar top-bar"></span>
                  <span className="icon-bar middle-bar"></span>
                  <span className="icon-bar bottom-bar"></span>
                </button>

                <div className={classOne} id="navbarSupportedContent">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a rel="noopener noreferrer" href="https://admin.memorial.network/mint" target="_blank">Get a Memorial</a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </React.Fragment>
  );
}

export default NavbarThree;
