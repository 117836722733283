import React, { useState } from 'react';
import { Modal, Button, Row, Col, Container } from 'react-bootstrap'
const DisabledModel = (props) => {

  const [owner, setOwner] = useState(null);
  const [selected, setSelected] = useState(false)

  return (
    <>
      <Modal
        {...props}
        size="sm"
        centered
      >
        <Modal.Body>
          There are memorials at this level (yet). Please view from the upper most level memorial downwards.
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: '#f8a035', borderColor: '#f8a035' }} onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DisabledModel;
