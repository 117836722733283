import React from 'react';
import NavbarThree from "../components/App/NavbarThree"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import MainBanner from '../components/DataAnalyticsMLConsulting/MainBanner';

const Home = () => {
    return (
        <Layout>
            <NavbarThree />
            <MainBanner />
        </Layout>
    );
}

export default Home;
