import React, { useEffect, useState, useRef } from 'react';
import {
  level1, level2, level3, level4, level5, level6, level7, level8, level9, level10, level11, level12, level13, level14, level15, level16, level17, level18
} from '../../assets/images/pyramid-slice/default'
import {
  selectedLevel1, selectedLevel2, selectedLevel3, selectedLevel4, selectedLevel5, selectedLevel6, selectedLevel7, selectedLevel8, selectedLevel9, selectedLevel10, selectedLevel11, selectedLevel12, selectedLevel13, selectedLevel14, selectedLevel15
} from '../../assets/images/pyramid-slice/selected'
import { useModal, Modal } from 'react-morphing-modal';
import 'react-morphing-modal/dist/ReactMorphingModal.css';
import LandArea from '../Land/LandArea';

const levels = 18;

const defaultLevel = {
  1: level1,
  2: level2,
  3: level3,
  4: level4,
  5: level5,
  6: level6,
  7: level7,
  8: level8,
  9: level9,
  10: level10,
  11: level11,
  12: level12,
  13: level13,
  14: level14,
  15: level15,
  16: level16,
  17: level17,
  18: level18,
}

const selectedLevel = {
  1: selectedLevel1,
  2: selectedLevel2,
  3: selectedLevel3,
  4: selectedLevel4,
  5: selectedLevel5,
  6: selectedLevel6,
  7: selectedLevel7,
  8: selectedLevel8,
  9: selectedLevel9,
  10: selectedLevel10,
  11: selectedLevel11,
  12: selectedLevel12,
  13: selectedLevel13,
  14: selectedLevel14,
  15: selectedLevel15,
}

const Level = (props) => {
  const btnRef = useRef(null)
  const [displayLevel, setDisplayLevel] = useState({})
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    setDisplayLevel(defaultLevel);
  }, [selected])

  useEffect(() => {
    hoverLevel(selected)
  }, [selected])

  function hoverLevel(i) {
    i !== null && selectedLevel[i] !== undefined && setDisplayLevel({
      ...defaultLevel,
      [i]: selectedLevel[i]
    })
  }

  function clickLevel() {
    props.openModal(btnRef);
    props.toggleLand(props.i)
  }

  return (
    <>
      <img
        className="pyramid-level"
        src={displayLevel[props.i]}
        onMouseOver={() => setSelected(props.i)}
        onMouseOut={() => setSelected(0)}
        onFocus={() => setSelected(props.i)}
        onBlur={() => setSelected(0)}
        onClick={() => props.i === 1 ? clickLevel() : props.toggleDisabled(props.i)}
        // onClick={() => props.i === 1 ? props.toggleLand(props.i) : props.toggleDisabled(props.i)}
        style={{ position: 'relative' }}
      />
      <button style={{ padding: 0, border: 'none', position: 'absolute', zIndex: -1, left: '50%', background: '#f1a957' }} ref={btnRef}>&nbsp;</button>
    </>
  );
}

const Pyramid = (props) => {
  const { modalProps, open } = useModal()
  const [owner, setOwner] = useState(null);
  const [selected, setSelected] = useState(false);

  let pyramid = [];

  function getLevels() {
    for (let i = 1; i <= levels; i++) {
      pyramid.push(
        <>
          <Level openModal={open} i={i} {...props} />
          <Modal {...modalProps} closeButton={false} >
            <div className="land-area">
              <LandArea toggleMemorial={props.toggleMemorial.bind(this)} setSelected={setSelected.bind(this)} setOwner={setOwner.bind(this)} selected={selected} level={props.level} />
            </div>
          </Modal>
        </>
      )
    }
  }

  getLevels();

  return (
    <div className="pyramid">
      {pyramid}
    </div>
  )
}

export default Pyramid;
